import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import { ThemeProvider } from './components/Theme/ThemeToggle';

const Home = lazy(() => import('./pages/Home/Home'));
const About = lazy(() => import('./pages/About/About'));
const Games = lazy(() => import('./pages/Games/Games'));
const Projects = lazy(() => import('./pages/Projects/Projects'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const DemoHeader = lazy(() => import('./pages/DemoSites/DemoComponents/DemoHeader/DemoHeader'));
const DemoConstruction = lazy(() => import('./pages/DemoSites/DemoConstruction/DemoConstructionHomepage'));
const DemoConstructionServices = lazy(() => import('./pages/DemoSites/DemoConstruction/DemoConstructionService'));
const DemoConstructionProjects = lazy(() => import('./pages/DemoSites/DemoConstruction/DemoConstructionProjects'));
const DemoConstructionAbout = lazy(() => import('./pages/DemoSites/DemoConstruction/DemoConstructionAbout'));
const DemoConstructionContact = lazy(() => import('./pages/DemoSites/DemoConstruction/DemoConstructionContact'));


function App() {
  return (
    <Suspense fallback={<div className="dark-background" />}>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/games" element={<Games />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/demoRestaurantHome" element={<DemoHeader />} />
        <Route path="/constructionHome" element={<DemoConstruction />} />
        <Route path="/constructionServices" element={<DemoConstructionServices />} />
        <Route path="/constructionProjects" element={<DemoConstructionProjects />} />
        <Route path="/constructionAbout" element={<DemoConstructionAbout />} />
        <Route path="/constructionContact" element={<DemoConstructionContact />} />
      </Routes>
    </Router>
    </Suspense>
  );
}

export default App;